import React from 'react'
import { motion } from 'framer-motion'
import { useGlobalContext } from '../../components/Context/ContextProvider'
import { socialLinks } from '../../components/Navbar/Social'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { mouseSocialLinks, mouseDefault } = useGlobalContext()

  return (
    <footer class='flex w-full flex-col items-center justify-center px-10 py-10 font-saira shadow sm:px-24 md:flex-row  md:justify-between'>
      <span class='text-center text-lg text-white'>
        © {currentYear}
        <motion.a
          onMouseEnter={mouseSocialLinks}
          onMouseLeave={mouseDefault}
          href='#Home'
          class='ml-2'
          target='_blank'
        >
          ODDINARY
        </motion.a>
        . All Rights Reserved.
      </span>
      <ul class='mt-3 flex flex-wrap items-center justify-center text-lg text-white sm:mt-0'>
        {socialLinks.map((item, index) => (
          <motion.li
            key={index}
            onMouseEnter={mouseSocialLinks}
            onMouseLeave={mouseDefault}
          >
            <a
              href={item.url}
              rel='facebook noreferrer'
              class='mr-4 md:mr-6'
              target='_blank'
            >
              {item.name}
            </a>
          </motion.li>
        ))}
      </ul>
    </footer>
  )
}

export default Footer
